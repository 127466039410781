















































































































































import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { TAX_TYPE } from "@/models/enums/tax.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { AddressDataDto } from "@/models/interface/ContactDataDto.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListOfTax } from "@/models/interface/settings.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { PropsModel } from "@/models/interfaces/common.interface";
import { contactServices } from "@/services/contact.service";
import { productService } from "@/services/product.service";
import { settingsServices } from "@/services/settings.service";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import { Dropdown } from "./InvoiceArIndex.vue";

const Props = Vue.extend({
  props: [
    "handleInputDetails",
    "dataSourceDetailsTabpane",
    "showConfirmation",
    "selectedRowKeysDetails",
    "onSelectChangeDetails",
    "handleSelectTableDetails",
    "dataCustomerShipAddress",
    "disabled",
    "changeDataInput",
    "changeSource",
    "disableButton",
    "getCheckboxPropsDetail",
    "detailInvoice",
    "handleSwitchDetails",
    "handleInputNumberDetails",
    // "optUom",
  ],
});

@Component({
  computed: {
    ...mapState({
      storeForm: (store: any) => store.invoiceStore.form,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatterPercent,
    reverseFormatPercent,
    ...mapMutations({
      setForm: "invoiceStore/SET_FORM",
    }),
  },
})
export default class InvoiceArDetails extends Props {
  columnsTable = [
    {
      title: this.$t("lbl_product_code"),
      dataIndex: "productCode",
      key: "productCode",
      width: 300,
      fixed: "left",
      scopedSlots: { customRender: "productCode" },
      responsiveColSelect: [
        {
          name: "productCode",
          placeholder: this.$t("lbl_product_code"),
          value: "id",
          options: [] as [],
          style: "width: 100%",
        },
        {
          name: "productName",
          placeholder: this.$t("lbl_product_name"),
          style: "width: 100%;",
          value: "id",
          options: [] as [],
        },
        {
          name: "revenueAccount",
          placeholder: this.$t("lbl_revenue_account"),
          value: "id",
          options: [] as [],
          style: "width: 100%",
        },
        {
          name: "uomId",
          placeholder: this.$t("lbl_uom"),
          style: "width: 100%;",
          value: "id",
          // options: [] as [],
        },
        {
          name: "customerLocation",
          placeholder: this.$t("lbl_customer_location"),
          style: "width: 100%;",
          value: "value",
          options: [] as OptionModel[],
        },
        {
          name: "taxCode",
          placeholder: this.$t("lbl_tax_code"),
          style: "width: 100%;",
          value: "id",
          options: [] as [],
        },
        {
          name: "salesId",
          placeholder: this.$t("lbl_sales_name"),
          style: "width: 100%;",
          value: "id",
          options: [] as ContactData[],
          loading: false,
        },
        {
          name: "incomeAccountTaxId",
          placeholder: this.$t("lbl_income_account_tax"),
          style: "width: 100%;",
          value: "id",
          options: [] as OptionModel[],
          loading: false,
        },
      ],
      responsiveColInput: [
        {
          name: "documentReference",
          placeholder: this.$t("lbl_document_reference"),
          style: "width: 100%;",
        },
      ],
      responsiveColInputNumber: [
        {
          name: "qty",
          placeholder: this.$t("lbl_qty"),
          style: "width: 100%;",
        },
        {
          name: "price",
          placeholder: this.$t("lbl_price"),
          style: "width: 100%;",
        },
      ],
      responsiveColTextArea: [
        {
          name: "description",
          placeholder: this.$t("lbl_description"),
          style: "width: 100%;",
        },
      ],
      responsiveColSwitch: [
        {
          name: "includePPh",
        },
      ],
    },
    {
      title: this.$t("lbl_product_name"),
      dataIndex: "productName",
      key: "productName",
      width: 300,
      scopedSlots: { customRender: "productName" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      key: "documentReference",
      // width: 300,
      scopedSlots: { customRender: "documentReference" },
    },
    {
      title: this.$t("lbl_sales_name"),
      dataIndex: "salesId",
      // width: 300,
      key: "salesId",
      scopedSlots: { customRender: "salesId" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      key: "merk",
      // width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      // width: 150,
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uomId",
      key: "uomId",
      // width: 150,
      scopedSlots: { customRender: "uomId" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      // width: 200,
      scopedSlots: { customRender: "price" },
    },
    {
      title: this.$t("lbl_revenue_account"),
      dataIndex: "revenueAccount",
      key: "revenueAccount",
      // width: 250,
      scopedSlots: { customRender: "revenueAccount" },
    },
    {
      title: this.$t("lbl_base_amount"),
      dataIndex: "baseAmount",
      key: "baseAmount",
      // width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_include_pph"),
      dataIndex: "includePPh",
      key: "includePPh",
      // width: 150,
      scopedSlots: { customRender: "includePPh" },
    },
    {
      title: this.$t("lbl_income_tax"),
      dataIndex: "incomeAccountTaxId",
      key: "incomeAccountTaxId",
      // width: 250,
      scopedSlots: { customRender: "incomeAccountTaxId" },
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      key: "taxCode",
      // width: 250,
      scopedSlots: { customRender: "taxCode" },
    },
    {
      title: this.$t("lbl_tax_amount"),
      dataIndex: "taxValue",
      key: "taxValue",
      // width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_sub_total"),
      dataIndex: "subTotal",
      key: "subTotal",
      // width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      // width: 250,
      scopedSlots: { customRender: "description" },
    },
    {
      title: this.$t("lbl_location_customer"),
      dataIndex: "customerLocation",
      key: "customerLocation",
      // width: 300,
      scopedSlots: { customRender: "customerLocation" },
    },
    {
      title: this.$t("lbl_action"),
      key: "viewMore",
      scopedSlots: { customRender: "viewMore" },
      // width: 120,
      align: "center",
    },
  ] as ColumnTableCustom[];
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_total",
      decorator: [
        "total",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    discountAmountPercent: {
      name: "discountAmountPercent",
      placeholder: "lbl_discount_percent",
      decorator: [
        "discountAmountPercent",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    discountAmountValue: {
      name: "discountAmountValue",
      placeholder: "lbl_discount_amount",
      decorator: [
        "discountAmountValue",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    totalTax: {
      label: "lbl_total_tax",
      name: "totalTax",
      placeholder: "lbl_total_tax",
      decorator: [
        "totalTax",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    totalPrepayment: {
      label: "lbl_total_prepayment",
      name: "totalPrepayment",
      placeholder: "lbl_total_prepayment",
      decorator: [
        "totalPrepayment",
        {
          rules: [
            {
              required: false,
            },
          ],
        },
      ],
    },
    grandTotal: {
      label: "lbl_grand_total",
      name: "grandTotal",
      placeholder: "lbl_grand_total",
      decorator: [
        "grandTotal",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  sortByType = true as boolean;
  type!: string;
  mode!: Mode;

  @Watch("dataSourceDetailsTabpane.length")
  public onChangeDataSourceDetailsTabpane(newValue) {
    if (!newValue) return;
    this.lookupSales();
  }

  @Watch("dataCustomerShipAddress")
  dataChangeCustomerShipAddress(value: Array<AddressDataDto>): void {
    if (!value || !this.colCustomerLocation) return;
    const { toContactLocationOptions } = useContactData();
    this.colCustomerLocation.options = toContactLocationOptions(value);
  }

  @Watch("changeSource")
  public changeDataSource(value: number): void {
    if (value < 1) return;
    this.sortByType = true;
    this.getListOfProductCode("", this.sortByType);
    this.getListOfProductName("", this.sortByType);
    this.getListOfSalesName();
  }

  created(): void {
    this.type = this.$route.meta.type;
    this.mode = this.$route.meta.mode;
    this.getListOfProductCode = debounceProcess(this.getListOfProductCode);
    this.getListOfProductName = debounceProcess(this.getListOfProductName);
    this.getListOfRevenue = debounceProcess(this.getListOfRevenue);
    this.getListOfTax = debounceProcess(this.getListOfTax);
    this.getListOfTaxIncome = debounceProcess(this.getListOfTaxIncome);
    this.getListOfSalesName = debounceProcess(this.getListOfSalesName);
  }

  mounted() {
    this.getListOfProductCode("", this.sortByType);
    this.getListOfProductName("", this.sortByType);
    this.getListOfRevenue("");
    this.getListOfTax("");
    this.getListOfTaxIncome();
    this.getListOfSalesName();
  }

  get isRecurring(): boolean {
    return this.type.includes("recurring");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };
  }

  /**
   * find column income tax on columns table
   */
  get colUom(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(item => item.name === "uom") ||
      undefined
    );
  }

  /**
   * find column income tax on columns table
   */
  get colIncomeTax(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(
        item => item.name === "incomeAccountTaxId"
      ) || undefined
    );
  }

  /**
   * find column sales on columns table
   */
  get colSales(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(item => item.name === "salesId") ||
      undefined
    );
  }

  /**
   * find column taxCode on columns table
   */
  get colTaxCode(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(item => item.name === "taxCode") ||
      undefined
    );
  }

  /**
   * find column productCode on columns table
   */
  get colProductCode(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(
        item => item.name === "productCode"
      ) || undefined
    );
  }

  /**
   * find column productName on columns table
   */
  get colProductName(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(
        item => item.name === "productName"
      ) || undefined
    );
  }

  /**
   * find column revenueAccount on columns table
   */
  get colRevenueAccount(): PropsModel | undefined {
    const colSelect = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colSelect?.responsiveColSelect.find(
        item => item.name === "revenueAccount"
      ) || undefined
    );
  }

  /**
   * find column customer location on columns table
   */
  get colCustomerLocation(): PropsModel | undefined {
    const colText = this.columnsTable.find(col => col.responsiveColSelect);
    return (
      colText?.responsiveColSelect.find(
        item => item.name === "customerLocation"
      ) || undefined
    );
  }

  /**
   * match sales from table row with sales options
   */
  lookupSales(): void {
    this.dataSourceDetailsTabpane.forEach(item => {
      const match = this.colSales?.options.some(
        option => option.id === item.salesId
      );
      if (!match) {
        this.colSales?.options.push({
          id: item.salesId,
          fullName: item.salesName,
          name: item.salesName,
        });
      }
    });
  }

  handleSearchSelectTable(value, _key, colName): void {
    switch (colName) {
      case "productCode":
        this.getListOfProductCode(value, this.sortByType);
        break;
      case "productName":
        this.getListOfProductName(value, this.sortByType);
        break;
      case "revenueAccount":
        this.getListOfRevenue(value);
        break;
      case "taxCode":
        this.getListOfTax(value);
        break;
      case "incomeAccountTaxId":
        this.getListOfTaxIncome(value);
        break;
      case "salesName":
        this.getListOfSalesName(value);
        break;
      default:
        break;
    }
  }

  getListOfSalesName(search = ""): void {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "employeeData.position~*Sales*_AND_employee~true_AND_active~true",
    };
    if (search && this.isRecurring) {
      params.search += `_AND_firstName~*${search}*_OR_lastName~*${search}*`;
    }

    if (this.colSales) this.colSales.loading = true;
    contactServices
      .listContactData(params)
      .then(response => {
        if (!this.colSales) return;
        this.colSales.options = response.data.map(item => ({
          ...item,
          name: item.fullName,
        }));
      })
      .finally(() => {
        if (this.colSales) this.colSales.loading = false;
      });
  }

  /**
   * @param {boolean} sortByType
   */
  getListOfProductCode(value: string, sortByType): void {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value && !sortByType)
      params.search = `description~*${value}*_OR_code~*${value}*`;
    if (value && sortByType)
      params.search = `description~*${value}*_OR_code~*${value}*_AND_type~Service_OR_type~Non Stockable`;
    if (!value && sortByType)
      params.search = `type~Non Stockable_OR_type~Service`;
    productService.listProduct(params).then(res => {
      const listDropdown = [] as Dropdown[];
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["nameUse"] = item.name;
        item["name"] = item.code;
        listDropdown.push(item);
      });
      if (this.colProductCode) this.colProductCode.options = listDropdown;
    });
  }
  getListOfTax(value): void {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
      search: `taxType~VAT_OUT`,
    } as RequestQueryParamsModel;
    if (value)
      params.search = `code~*${value}*_OR_description~*${value}*_AND_taxType~VAT_OUT`;

    settingsServices.listOfTax(params, "").then(res => {
      const listDropdown = [] as Dropdown[];
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["name"] = item.code;
        item["id"] = item.code as string;
        listDropdown.push(item);
      });
      if (this.colTaxCode) this.colTaxCode.options = listDropdown;
      this.$emit("dataTaxCode", listDropdown);
    });
  }
  getListOfProductName(value: string, sortByType): void {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;

    if (value && !sortByType)
      params.search = `description~*${value}*_OR_code~*${value}*`;
    if (value && sortByType)
      params.search = `description~*${value}*_OR_code~*${value}*_AND_type~Service_OR_type~Non Stockable_OR_type~General`;
    if (!value && sortByType)
      params.search = `type~Service_OR_type~Non Stockable_OR_type~General`;

    productService.listProduct(params).then(res => {
      const listDropdown = [] as Dropdown[];
      res.data.forEach((item, index) => {
        item["key"] = index;
        listDropdown.push(item);
      });
      if (this.colProductName) this.colProductName.options = listDropdown;
    });
  }
  getListOfRevenue(value) {
    let params = {
      limit: 10,
      page: 0,
      search: `accountType.name~*Pendapatan*`,
    } as RequestQueryParamsModel;
    if (value)
      params.search += `_AND_description~*${value}*_OR_code~*${value}*`;
    settingsServices.listOfCoa(params, "").then((res: any) => {
      const listDropdown = [] as Dropdown[];
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["name"] = `${item.code} - ${item.description}`;
        listDropdown.push(item);
      });
      if (this.colRevenueAccount) this.colRevenueAccount.options = listDropdown;
    });
  }

  onViewMore(param: { record: PropsModel; index: number }): void {
    this.$emit("on-view-more", param);
  }

  getListOfTaxIncome(value?: string): void {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
      search: `taxType~${trimSpaceToUnderscore(
        TAX_TYPE.INCOME_TAX_RECEIVABLES
      )}`,
    } as RequestQueryParamsModel;
    if (value) {
      params.search = `code~*${value}*_OR_description~*${value}*_AND_taxType~${trimSpaceToUnderscore(
        TAX_TYPE.INCOME_TAX_RECEIVABLES
      )}`;
    }
    settingsServices.listOfTax(params, "").then((res: ResponseListOfTax) => {
      if (!this.colIncomeTax) return;
      this.colIncomeTax.options = res.data.map<OptionModel>(item => ({
        id: item.id,
        name: item.code,
        rate: item.rate,
      }));
    });
  }

  addRow(): void {
    this.$emit("on-add-row");
  }
}
